@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url("../public/fonts/Gilroy-Regular.ttf") format("truetype");


}

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

#root,
html,
body {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GilroyRegular";
}




p {
  font-family: "GilroyRegular" !important;
  vertical-align: middle !important;

}